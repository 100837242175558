<template>
    <div ref="cons" class="home">
        <head-nav></head-nav>
        <div class="left-fixed-right-auto">
            <left-menu></left-menu>
             <div  class="content_page" 
            :style="{'margin-left':$store.state.menu.sidebar.width,
            'width':win_size.width+'px',
            }">
                <bread></bread>
                <div  class="content">
                    <transition name="z-fade">
                        <router-view></router-view>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import HeadNav from './headNav.vue';
	import LeftMenu from './leftMenu.vue';
	import Bread from './bread.vue';
    export default {
        name: 'home',
        data(){
            return {
                win_size: {
                   height: '',
                   width:'',
                },
            }
        },
        components:{
            HeadNav,
            LeftMenu,
            Bread
        },
        methods: {
            //用于自适配窗口页面大小 
            setSize() {
                // console.log(document.body.clientHeight)
                // lib_$-->$,window的宽,高的获取是没有问题的。
                this.win_size = {
                    height:document.body.clientHeight-108,
                    width:document.body.clientWidth-180
                }
                // console.log(this.win_size)
                // 将content部分的宽高，存入store中，
                this.$store.dispatch('set_win_content',this.win_size); //触发动作，content部分的宽高也随即改变。
            },
            isSetSize(){
                this.win_size = {
                    height:document.body.clientHeight-108,
                    width:document.body.clientWidth-64
                }
                this.$store.dispatch('set_win_content',this.win_size); //触发动作，content部分的宽高也随即改变。
            }
        },
        created() {
                this.setSize();
        },
        mounted(){
            
            window.onresize = () => {
                // console.log(this.$store.state.menu.sidebar.opened,232323)
                if(this.$store.state.menu.sidebar.opened){
                    this.setSize();
                }else{
                    this.isSetSize();
                }
            }
        }
    };
</script>
<style scoped lang='less'>
    .content_page{
        position: fixed;
        top:71px;
        background: #FFF;
        height: 80%;
        z-index: 3;
    }
    .content {
        width:100%; 
        height:100%;
        overflow-x:hidden;
        padding-top:5px;
        margin-bottom: 20px;
        // border-right: 1px solid rgba(0,0,0,.07);
        // transition: transform .25s ease-out;
    }
    // .content::-webkit-scrollbar {
    //     width: 4px
    // }
    // .content::-webkit-scrollbar-thumb {
    //     background: transparent;
    //     border-radius: 4px
    // }
    // .content:hover::-webkit-scrollbar-thumb {
    //     background: hsla(0,0%,53%,.4)
    // }
    // .content:hover::-webkit-scrollbar-track {
    //     background: hsla(0,0%,53%,.1)
    // }

    

    .z-fade-enter-active, .z-fade-leave-active{
	  	transition: all 0.5s;
	}
	.z-fade-enter, .z-fade-leave-active {
	  	opacity: 0;
	}
</style>
