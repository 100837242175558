<template>
        <el-row class="menu_page">
           <el-scrollbar style="height:100%;overflow-x: hidden;">
            <el-col  style="height:100%;" class="meu_box">

            <el-menu mode="vertical"
                theme="dark"
                class="el-menu-vertical-demo"
                :default-active="$route.path"
                :collapse="$store.state.menu.isCollapse"
                background-color="#fff"
                text-color="#333"
                :router="true"
                :unique-opened="true"
                active-text-color="#42b983">

                <el-menu-item index="/index">
                    <i class="el-icon-s-custom"></i>
                    <span slot="title">首页</span>
                </el-menu-item>
                <el-menu-item index="/user/userInfo">
                        <i class="el-icon-s-custom"></i>
                        <span slot="title">基础资料</span>
                </el-menu-item>
                <el-submenu index="care">
                    <template slot="title">
                        <i class="el-icon-s-custom"></i>
                        <span slot="title">购物车</span>
                    </template>
                    <el-menu-item index="/report/produce">购物车列表</el-menu-item>
                    <el-menu-item index="/report/produce">购物车管理</el-menu-item>
                </el-submenu>
                 <el-submenu index="order">
                    <template slot="title">
                        <i class="el-icon-s-custom"></i>
                        <span slot="title">订单管理</span>
                    </template>
                    <el-menu-item index="/user/order">订单列表</el-menu-item>

                </el-submenu>


             <!--  <el-submenu index="base">
                <template slot="title">
                  <i class="fa fa-user-circle-o"></i>
                  <span slot="title">基础资料</span>
                </template>
                <el-submenu index="/Administration">
                    <span style="margin-left: 10px" slot="title">行政相关</span>
                    <el-menu-item index="/staff/index">员工资料</el-menu-item>
                    <el-menu-item index="/position/position">职位资料</el-menu-item>
                    <el-menu-item index="/base/department">部门资料</el-menu-item>
                </el-submenu>
                <el-submenu index="/transport">
                    <span style="margin-left: 10px" slot="title">运输相关</span>
                    <el-menu-item index="/car/carlist">车辆信息</el-menu-item>
                </el-submenu>
                <el-submenu index="/produce">
                    <span style="margin-left: 10px" slot="title">生产相关</span>
                    <el-menu-item index="">坑别</el-menu-item>
                    <el-menu-item index="1-4-1">楞别</el-menu-item>
                    <el-menu-item index="1-4-1d">生产线</el-menu-item>
                    <el-menu-item index="/pmc/pmcpaper">原纸代码表</el-menu-item>
                </el-submenu>
              </el-submenu>

              <el-submenu index="sale">
                <template slot="title">
                    <i class="fa fa-money"></i>
                    <span slot="title">晨龙销售</span>
                </template>
                <el-menu-item index="/pmcOrder/order">订单销售</el-menu-item>
                <el-menu-item index="/customer/customerList">客户分析</el-menu-item>
                <el-menu-item index="/pmcOrder/cardboardSales">纸板销售</el-menu-item>
            </el-submenu>   -->

          <!--   <el-submenu index="production">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span slot="title">晨龙生产</span>
                </template>
                <el-menu-item index="/pmc/scheduling">生产排程</el-menu-item>
                <el-menu-item index="/employee">生产状况</el-menu-item>

            </el-submenu>
            <el-submenu index="storage">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span slot="title">晨龙原纸存储</span>
                </template>
                <el-menu-item index="/storehouse/bodyPaperStock">原纸库存</el-menu-item>
                 <el-menu-item index="/storehouse/originPaper">原纸入库</el-menu-item>
                <el-menu-item index="/storehouse/bodyPaperOut">原纸出退仓</el-menu-item>

            </el-submenu>
            <el-submenu index="transport">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span slot="title">晨龙储运</span>
                </template>
                <el-menu-item index="/storehouse/finishedProductList">纸板库存</el-menu-item>
                <el-menu-item index="/storehouse/finishedProductOut">纸板送货</el-menu-item>
                <el-menu-item index="/storehouse/finishedProductEnter">纸板入仓</el-menu-item>
            </el-submenu>  -->

         <!--    <el-submenu index="reportManage">
                <template slot="title">
                    <i class="fa fa-bar-chart"></i>
                    <span slot="title">报表管理</span>
                </template>
                <el-menu-item index="/report/produce">日报表</el-menu-item>
            </el-submenu>
 -->


            </el-menu>

            </el-col>
        </el-scrollbar>
        </el-row>
</template>
<script>

    export default {
		name:'left-Menu',
		data(){
			return {
                isDropdown:false,
			}
        },
        mounted() {
        },
        methods:{
           showDropdown(){

               this.isDropdown = this.$store.state.menu.isCollapse;
           },

        }
    };
</script>
<style lang="less" scoped>
	.menu_page{
		position:fixed;
		top:71px;
        left:0;
        height:100%;
        background-color: #fff;
        z-index: 9;
        padding-bottom:70px;
        overflow:auto;
	}
    .meu_box{
        display: block;
    }
    .fa-margin{
        margin-right:5px;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 180px;
        min-height: 600px;
    }
    .el-menu-vertical-demo{
        width:64px;
    }
    .el-submenu .el-menu-item{
        min-width:180px;
    }
    // .dropItem{
    //     padding-left:10px !important;
    // }
    .hiddenDropdown,
    .hiddenDropname{
        display:none;
    }
    .el-submenu [class^=el-icon-]{
        width:15px;
    }
    .menu_page /deep/.el-scrollbar__wrap ,.menu_page /deep/.el-scrollbar__view{
        height:100%;
    }
    .menubar{
        height: 100%;
    }


</style>
